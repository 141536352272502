<template>
  <div>
    <PageBar Image="img/media_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>
    <!-- <PageBar Image="img/essay_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar> -->

    <!-- 原數位增能平台 -->

    <div class="main">
      <div class="filterCol">
        <div class="keyWordSearch">
          <h3>關鍵字搜尋</h3>
          <input type="text" v-model="KeywordInput" />
          <button class="searchBtn" @click="searchKeyword">查詢</button>
        </div>
        <div class="periodSearch">
          <h3>時間區段搜尋</h3>
          <div class="dateFrom">
            從
            <input type="date" id="start" />
          </div>
          <div class="dateTo">
            至
            <input type="date" id="end" />
          </div>
          <button class="searchBtn" @click="searchDay">查詢</button>
        </div>
        <h3>課程分類</h3>
        <div class="collapse">
          <span class="hide_all">全部收合</span
          ><span class="show_all">全部展開</span>
        </div>
        <div class="courseType">
          <ul class="layer1">
            <template v-for="c in Categories">
              <li v-if="c.Show" :key="c.LessonCategoryID" class="">
                <h4>
                  <input
                    type="checkbox"
                    v-model="SelectedCategories"
                    :value="c.LessonCategoryID"
                    onclick="window.model.toggle(this)"
                  />{{ c.CategoryName
                  }}<img class="arrDown" src="img/arr_down.svg" />
                </h4>
                <ul class="layer2">
                  <template v-for="c1 in c.Children">
                    <li v-if="c1.Show" :key="c1.LessonCategoryID" class="open">
                      <h4>
                        <input
                          type="checkbox"
                          v-model="SelectedCategories"
                          :value="c1.LessonCategoryID"
                          onclick="window.model.toggle(this)"
                        />{{ c1.CategoryName
                        }}<img class="arrDown" src="img/arr_down.svg" />
                      </h4>
                      <ul class="layer3">
                        <template v-for="c2 in c1.Children">
                          <li v-if="c2.Show" :key="c2.LessonCategoryID">
                            <h4>
                              <input
                                type="checkbox"
                                v-model="SelectedCategories"
                                :value="c2.LessonCategoryID"
                                onclick="window.model.toggle(this)"
                              />{{ c2.CategoryName
                              }}<img class="arrDown" src="img/arr_down.svg" />
                            </h4>
                          </li>
                        </template>
                      </ul>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="coursesContainer">
        <h2>數位增能平台</h2>
        <div class="sortBy">
          <div
            class="list_icon"
            :class="{ on: Mode == 'list' }"
            @click="switchMode"
          >
            <img src="img/list_icon.svg" />
          </div>
          <span>排序依</span>
          <select v-model="SortBy">
            <option value="name_desc">課程名稱筆劃多至少</option>
            <option value="author_asc">課程作者筆劃少至多</option>
            <option value="price_desc">使用點數由多至少</option>
            <option value="price_asc">使用點數由少至多</option>
          </select>
          <a href="javascript:void(0)" @click="showBuy" class="buyPoint"
            >購買點數</a
          >
        </div>
        <div class="courseList c_list" v-if="Mode == 'list'">
          <div class="list_style lesson">
            <table>
              <tr>
                <th>收藏</th>
                <th>上架日期</th>
                <th>下架日期</th>
                <th>課程名稱</th>
                <th>作者</th>
                <th>使用點數</th>
                <!-- <th>期限</th> -->
              </tr>
              <tr v-for="l in Lessons" :key="l.LessonID">
                <td>
                  <div class="like">
                    <img
                      src="img/love.svg"
                      :class="{ on: !l.IsFavorite }"
                      @click="addFavorite(l)"
                    />
                    <img
                      src="img/love_on.svg"
                      :class="{ on: l.IsFavorite }"
                      @click="delFavorite(l)"
                    />
                  </div>
                </td>
                <td><span>{{ l.StartTime || l.CreateTime | dateFormat }}</span></td>
                <td>
                  <span v-if="l.EndTime">{{ l.EndTime | dateFormat }}</span
                  ><span v-else>無期限</span>
                </td>
                <td>
                  <a href="javascript:void(0);" @click="go(l)">{{ l.Title }}</a>
                  <small>{{ l.SubTitle }}</small>
                </td>
                <td>{{ l.Author }}</td>
                <td>{{ l.Price | commaFormat }}</td>
                <!-- <td>{{ l.UsableDays }}天</td> -->
              </tr>
            </table>
          </div>
          <div class="pagination">
            <div class="prev10" v-if="ShowPrev10">
              <img src="img/prev10.png" alt="" @click="prev10" />
            </div>
            <ul>
              <li v-for="p in Pages" :key="p">
                <a
                  :class="{ act: p == Page }"
                  href="javascript:void(0);"
                  @click="showPage(p)"
                  >{{ p }}</a
                >
              </li>
            </ul>
            <div class="next10" v-if="ShowNext10">
              <img src="img/next10.png" alt="" @click="next10" />
            </div>
          </div>
        </div>
        <div class="courseList c_icon" v-else :key="listID">
          <div id="waterfallArea" v-masonry after="imagesLoaded">
            <div class="content_box" v-for="l in Lessons" :key="l.LessonID">
              <a href="javascript:void(0);" @click="go(l)"
                ><img :src="l.ImageUrl" />
                <h3>
                  {{ l.Title }}
                </h3>
                <h4>{{ l.SubTitle }}</h4>
                <div class="courseInfo">
                  <div class="author">
                    <p>{{ l.Author }}</p>
                  </div>
                  <div class="price">
                    使用點數<strong>{{ l.Price | commaFormat }}</strong>
                  </div>
                </div>
                <div class="date">
                  {{ l.StartTime || l.CreateTime | dateFormat }} ~
                  <span v-if="l.EndTime">{{ l.EndTime | dateFormat }}</span
                  ><span v-else>無期限</span>
                  <span class="usableDays">期限:{{ l.UsableDays }}天</span>
                </div></a
              >
              <div class="like">
                <img
                  src="img/love.svg"
                  :class="{ on: !l.IsFavorite }"
                  @click="addFavorite(l)"
                />
                <img
                  src="img/love_on.svg"
                  :class="{ on: l.IsFavorite }"
                  @click="delFavorite(l)"
                />
              </div>
            </div>
          </div>
          <div class="pagination">
            <div class="prev10" v-if="ShowPrev10">
              <img src="img/prev10.png" alt="" @click="prev10" />
            </div>
            <ul>
              <li v-for="p in Pages" :key="p">
                <a
                  :class="{ act: p == Page }"
                  href="javascript:void(0);"
                  @click="showPage(p)"
                  >{{ p }}</a
                >
              </li>
            </ul>
            <div class="next10" v-if="ShowNext10">
              <img src="img/next10.png" alt="" @click="next10" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popUp buyPointDialog hide">
      <div class="alertWindow" v-if="Order">
        <div class="p_head">
          <p>購買程序</p>
        </div>
        <div class="p_content">
          <div class="steps">
            <p>申請購買點數</p>
            <p>匯款</p>

            <p>
              以EMAIL回覆匯款帳號後五碼<span
                >(請寄至:<a href="mailto:tassm2000@gmail.com"
                  >tassm2000@gmail.com</a
                >)</span
              >
            </p>
            <p>一至兩個工作天後可於信箱收取購買成功EMAIL</p>
          </div>
          <div class="pointChoose">
            欲購買點數
            <select v-model="Order.Point">
              <option :value="200">200</option>
              <option :value="400">400</option>
              <option :value="600">600</option>
              <option :value="800">800</option>
              <option :value="1000">1000</option>
              <option :value="1200">1200</option>
              <option :value="1400">1400</option>
              <option :value="1600">1600</option>
              <option :value="1800">1800</option>
              <option :value="2000">2000</option>
            </select>
            點，總共<span class="total">{{ Order.Point * 1 }}</span
            >元。
            <div class="paymentInfo">
              匯款資訊如下：<br />
              <h5>台北富邦銀行城中分行</h5>
              代碼：012 匯款帳號：00500120000490<br />
              戶名：社團法人臺灣體育運動管理學會<br />
            </div>
            <div class="vatRow">
              購買發票需要統一編號：<input
                type="number"
                class="vat"
                v-model="Order.VATNumber"
              />
            </div>
          </div>
          <div class="applyDoc">
              <h3>臺灣體育運動管理學會官方網站<br>數位增能平台點數使用須知</h3>
              <h4>一、適用範圍公告：</h4>
              <p> 數位學習點數可線上兌換本會透過網頁之體育相關學術研討講演、體育選手訪談、等影片進行觀賞，各影片所需點數請以本會網路公告為主。 </p>
              <h4>二、購買點數：</h4>
              <ol>
                <li>購買者可自行決定購買點數，每次以200點為單位購買。</li>
                <li>如有需一次性購買萬點以上(大額採購)，請洽本會(02-28861261)辦理。</li>
                <li>兌換本會學習點數，依購入順序依序扣抵。</li>
              </ol>
              <h4>三、售價：</h4>
              <ol>
                <li>單次購買點數每點1元，以200點為最低購買單位。</li>
              </ol>
              <h4>四、付費方式：</h4>
              <ol>
                <li>可選擇使用ATM轉帳付款方式，須一至兩個工作日進行帳務處理，方可完成付款程序。 匯款資訊如下：<br>台北富邦銀行城中分行<br>代碼：012 匯款帳號：00500120000490<br>戶名：社團法人臺灣體育運動管理學會</li>
                <li>依加值型及非加值型營業稅法第32條規定，點數售出時本會已開立電子發票或收據，後續使用點數觀看影片時，恕不再開立電子發票或收據。</li>
                <li>跨行匯款手續費由消費者自行吸收。</li>
              </ol>
              <h4>五、選課方式：</h4>
              <ol>
                <li>購買點數並完成付款，且收到本會購買點數成功之通知信函後，即可開始以點數兌換課程。</li>
                <li>使用點數兌換課程，請於課程開始觀看請於30天內觀賞完畢，期間內無觀看次數限制。</li>
              </ol>
              <h4>六、點數退費方式：</h4>
              <ol>
                <li>首次購買點數並完成付款，且尚未已點數扣抵任何課程者，若付款後7日內辦理退費，將無息退還全額款項；若超過7日後辦理退費，將以您當初購買之點數金額扣除10%行政手續費後，無息退還剩餘款項。</li>
                <li>購買點數並完成付款後，若曾以點數扣抵課程，且欲辦理退費，將以您目前帳戶內之剩餘點數依當初購買點數之金額換算為現金，並扣除10%行政手續費後，根據消費者保護法第19-2條第二項規定，於15天內無息退還剩餘之款項，若款項為零或負數，將不再退款或收費。</li>
                <li>申請點數退費，皆以當初各次購買之點數價格換算為現金，但本會會員贈送之點數不得申請退費或折換現金。</li>
                <li>大額採購者，若曾以點數扣抵課程，因故辦理點數退費，致已購買之點數未達大額採購優惠標準，需扣除已使用優惠後，再退還款項。</li>
              </ol>
              <h4>七、本平台數位學習點數無使用期限</h4>
              <label for="agreeCheck"><input type="checkbox" id="agreeCheck" v-model="Order.ShowSubmit"> 本人已閱覽並同意以上購買點數之規定 </label>
            </div>
        </div>

        <div class="p_foot">
          <a
            href="javascript:void(0)"
            @click="closeOrderModal"
            class="cancel_btn"
            >取消</a
          >
          <a
            href="javascript:void(0)"
            :class="{ disable: !Order.ShowSubmit }"
            @click="submitOrder"
            >申請購買</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageBar from "@/components/PageBar.vue";
import moment from "moment";
import user from "../plugins/user";

function initial(_this) {
  (function ($) {
    $(window).on("load", function () {
      $(".scrollContent").mCustomScrollbar();
    });
  })(jQuery);

  $(".logos_slide").slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  $(".layer1>li>h4>input").click(function () {
    if ($(this).parents(".layer1>li").hasClass("on")) {
      // $(this).removeClass("on");
      $(this).parents(".layer1>li").removeClass("on");
    } else {
      $(this).parents(".layer1>li").addClass("on");
    }
  });
  $(".layer2>li>h4>input").click(function () {
    if ($(this).parents(".layer2>li").hasClass("on")) {
      // $(this).removeClass("on");
      $(this).parents(".layer2>li").removeClass("on");
    } else {
      $(this).parents(".layer2>li").addClass("on");
      $(this).parents(".layer2>li").addClass("on");
    }
  });
  $(".layer3>li>h4>input").click(function () {
    if ($(this).parents(".layer3>li").hasClass("on")) {
      // $(this).removeClass("on");
      $(this).parents(".layer3>li").removeClass("on");
    } else {
      $(this).parents(".layer3>li").addClass("on");
    }
  });

  $(".layer1>li>h4>.arrDown").click(function () {
    if ($(this).parents(".layer1>li").hasClass("open")) {
      // $(this).removeClass("open");
      $(this).parents(".layer1>li").removeClass("open");
    } else {
      $(".layer1>li").removeClass("open");
      $(this).parents(".layer1>li").addClass("open");
    }
  });
  $(".layer2>li>h4>.arrDown").click(function () {
    if ($(this).parents(".layer2>li").hasClass("open")) {
      // $(this).removeClass("open");
      $(this).parents(".layer2>li").removeClass("open");
    } else {
      $(".layer2>li").removeClass("open");
      $(this).parents(".layer2>li").addClass("open");
      $(this).parents(".layer2>li").addClass("open");
    }
  });
  $(".layer3>li>h4>.arrDown").click(function () {
    if ($(this).parents(".layer3>li").hasClass("open")) {
      // $(this).removeClass("open");
      $(this).parents(".layer3>li").removeClass("open");
    } else {
      $(this).parents(".layer3>li").addClass("open");
    }
  });

  $(".show_all").click(function () {
    $(".courseType li").addClass("open");
  });
  $(".hide_all").click(function () {
    $(".courseType li").removeClass("open");
  });
}

const PageSize = 8;

var map = {};
var all = [];
var result;
var fmap = {};
var auths = {};
var uauths = {};

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Categories: [],
      Lessons: [],
      Page: 1,
      MaxPage: 0,
      Pages: [],
      SortBy: "name_desc",
      Keyword: "",
      KeywordInput: "",
      StartDay: "",
      EndDay: "",
      SelectedCategories: [],
      listID: new Date().getTime(),
      Order: null,
      Config: null,
      Mode: "list",
    };
  },
  mounted() {
    window.model = this;
    this.setBase("media_platform");
    if (user.user) {
      user.user.type.forEach((u) => (uauths[u] = true));
    } else {
      uauths[-1] = true;
    }
    this.load();
  },
  methods: {
    async load() {
      result = await this.$api.getLessons();
      var config = await this.$api.getConfig("MasBuyPoints");
      if (!config) {
        this.Config = {
          Point: 0,
          Price: 0,
          Fee: 0,
        };
      } else {
        this.Config = JSON.parse(config);
      }

      try {
        if (user.user) {
          var favs = await this.$api.getFavorite();
          fmap = {};
          favs.forEach((f) => (fmap[f] = true));
        }
      } catch (e) {
        console.error(e);
      }

      var root = { Children: [], LessonCategoryID: null };
      this.createCategory(result.Categories, root);
      this.checkCategoryAuth(root.Children);
      this.Categories = root.Children;
      /*result.Categories.forEach((c) =>
        this.SelectedCategories.push(c.LessonCategoryID)
      );*/
      console.log(auths);
      all = [];
      result.Lessons.forEach((l) => {
        l.IsFavorite = fmap[l.LessonID];
        if (auths[l.LessonCategoryID]) {
          all.push(l);
          map[l.LessonID] = l;
        }
      });
      console.log(all);
      this.show(1);

      this.Breadcrumbs = [{ Title: "數位增能平台", Url: "#" }];

      this.$nextTick(() => {
        initial(this);
      });

      this.loadCustomJs();
    },
    createCategory(Category, parent) {
      Category.forEach((m) => {
        if (m.ParentLessonCategoryID == parent.LessonCategoryID) {
          if (!parent.Children) {
            parent.Children = [];
          }
          parent.Children.push(m);
          this.createCategory(Category, m);
        }
      });
    },
    checkCategoryAuth(Category) {
      Category.forEach((m) => {
        if (!m.Auths) return;
        var ahs = m.Auths.split(",");
        for (var i in ahs) {
          m.Show = uauths[ahs[i]];
          if (m.Show) {
            auths[m.LessonCategoryID] = true;
            break;
          }
        }
        if (m.Show && m.Children) {
          this.checkCategoryAuth(m.Children);
        }
      });
    },
    showPage(page) {
      this.Page = page;
      this.show();
    },
    searchKeyword() {
      this.Keyword = this.KeywordInput;
      this.StartDay = null;
      this.EndDay = null;
      this.showPage(1);
    },
    searchDay() {
      this.Keyword = null;
      this.StartDay = $("#start").val();
      this.EndDay = $("#end").val();
      this.showPage(1);
    },
    toggle(c) {
      if ($(c).prop("checked")) {
        $("input", $(c).parent().siblings()).prop("checked", true);
        $("input", $(c).parent().siblings())
          .get()
          .forEach((cc) => {
            let el = $(cc).get(0);
            let event = document.createEvent("Events");
            event.initEvent("change", true, false);
            el.dispatchEvent(event);
          });
        $("li", $(c).parent().siblings()).addClass("on");
      } else {
        $("input", $(c).parent().siblings()).prop("checked", false);
        $("input", $(c).parent().siblings())
          .get()
          .forEach((cc) => {
            let el = $(cc).get(0);
            let event = document.createEvent("Events");
            event.initEvent("change", true, false);
            el.dispatchEvent(event);
          });
        $("li", $(c).parent().siblings()).addClass("on");
      }
    },
    go(lesson) {
      this.$router.push(`/lesson/${lesson.LessonID}`);
    },
    show() {
      console.log("show", all);
      var start = (this.Page - 1) * PageSize;
      var sort = this.SortBy;
      var list = [];
      if (sort == "default") {
        list = all;
      } else if (sort == "price_asc") {
        result.SortedByPrice.forEach((id) => list.push(map[id]));
      } else if (sort == "price_desc") {
        result.SortedByPrice.forEach((id) => list.push(map[id]));
        list.reverse();
      } else if (sort == "author_asc") {
        result.SortedByAuthor.forEach((id) => list.push(map[id]));
      } else if (sort == "author_desc") {
        result.SortedByAuthor.forEach((id) => list.push(map[id]));
        list.reverse();
      } else if (sort == "name_desc") {
        result.SortedByName.forEach((id) => list.push(map[id]));
        list.reverse();
      }
      list = list.filter(x=> x != undefined);
      if (this.Keyword) {
        list = list.filter(
          (x) =>
            x.Title.indexOf(this.Keyword) >= 0 ||
            x.Author.indexOf(this.Keyword) >= 0 ||
            (x.Tag && x.Tag.indexOf(this.Keyword) >= 0)
        );
      }
      if (this.StartDay) {
        list = list.filter((x) => moment(x.CreateTime).isAfter(this.StartDay));
      }
      if (this.EndDay) {
        list = list.filter((x) => moment(x.CreateTime).isBefore(this.EndDay));
      }
      if (this.SelectedCategories.length > 0) {
        list = list.filter((x) =>
          this.SelectedCategories.includes(x.LessonCategoryID)
        );
      }
      var tlist = [];
      list.forEach((l) => {
        if (l.StartTime && moment(l.StartTime).isAfter(moment())) {
          console.log(`${l.Title} is before @${l.StartTime}`);
          return;
        }
        if (l.EndTime && moment(l.EndTime).isBefore(moment())) {
          console.log(`${l.Title} is after @${l.EndTime}`);
          return;
        }
        tlist.push(l);
      });
      list = tlist;

      this.Pages = [];
      this.MaxPage = Math.ceil(list.length / PageSize);
      if (this.MaxPage < this.Page && this.MaxPage >= 1) {
        this.Page = 1;
        this.show();
        return;
      }
      var basePage = Math.floor(this.Page / 10) * 10;
      for (var p = 1; p <= 10; p++) {
        if (this.Page % 10 == 0) {
          basePage = Math.floor((this.Page - 1) / 10) * 10;
        }
        var page = basePage + p;
        if (page > this.MaxPage) {
          break;
        }
        this.Pages.push(page);
      }
      console.log(list);
      this.Lessons = list.slice(start, start + PageSize);
      this.listID = new Date().getTime();
      setTimeout(() => {
        try {
          $(function () {
            //Fade Out the loading screen when all images loaded
            $("#waterfallArea")
              .imagesLoaded()
              .always(function (instance) {
                $(".loadingScreen").fadeOut();
              });
          });
        } catch (e) {}
      });
    },
    next10() {
      var p = this.Pages[this.Pages.length - 1] + 1;
      if (p < this.MaxPage) {
        this.showPage(p);
      } else {
        alert("沒有下十頁了");
      }
    },
    prev10() {
      var p = this.Pages[0] - 1;
      if (p > 0) {
        this.showPage(p);
      } else {
        alert("沒有上十頁了");
      }
    },
    async addFavorite(lesson) {
      if (!user.user) {
        alert("請先登入");
        return;
      }
      await this.$api.addFavoriteLesson(lesson.LessonID);
      lesson.IsFavorite = true;
    },
    async delFavorite(lesson) {
      if (!user.user) {
        alert("請先登入");
        return;
      }
      await this.$api.removeFavoriteLesson(lesson.LessonID);
      lesson.IsFavorite = false;
    },
    switchMode() {
      if (this.Mode == "icon") {
        this.Mode = "list";
        $(".list_icon").addClass("on");
      } else {
        this.Mode = "icon";
        $(".list_icon").removeClass("on");
      }
    },
    showBuy() {
      this.Order = {
        Point: 200,
        VATNumber: "",
        ShowSubmit: false,
      };
      $(".buyPointDialog").removeClass("hide");
      this.$nextTick(() => {
        $(".applyDoc").scrollTop(0);
      });
    },
    async submitOrder() {
      await this.$api.submitOrder(this.Order);
      alert("已收到您的申請");
      $(".buyPointDialog").addClass("hide");
    },
    closeOrderModal() {
      $(".buyPointDialog").addClass("hide");
    },
  },
  watch: {
    $route(to, from) {
      this.load();
    },
    SortBy(newValue, oldValue) {
      this.show();
    },
    SelectedCategories(newValue, oldValue) {
      this.Page = 1;
      this.show();
    },
  },
  computed: {
    ShowNext10() {
      return parseInt(this.Pages[this.Pages.length - 1]) + 1 < this.MaxPage;
    },
    ShowPrev10() {
      return parseInt(this.Pages[0]) > 10;
    },
  },
};
</script>